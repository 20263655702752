// @ts-nocheck
import * as React from "react";
import { InView } from "react-intersection-observer";
import { getUserAgent } from "../../utils/agent";
import { IUserAgent } from "../../types/ua";
interface ILazyProductImage {
  srcSet?: string;
  src: string;
  className?: string;
  height: string;
  style?: any;
  width: string;
  alt?: string;
  disableWebp?: boolean;
  disableAnim?: boolean;
  containerClassName?: string;
  children?: any;
}

export default function LazyProductImage(props: ILazyProductImage) {
  const [agent, setUserAgent] = React.useState<IUserAgent>({} as IUserAgent);
  React.useEffect(() => {
    const ua = getUserAgent();
    setUserAgent(ua);
  }, []);
  function getImageExtension(src?: string) {
    if (src && agent.hasWebp && !props.disableWebp) {
      return src.replace(/.jpg/g, ".webp").replace(/.png/g, ".webp");
    }
    return src;
  }

  function renderImage() {
    return (
      <img
        alt={props.alt}
        src={getImageExtension(props.src)}
        width={props.width}
        height={props.height}
        srcSet={getImageExtension(props.srcSet)}
        className={
          (props.className || "") +
          (!props.disableAnim ? " animated fadeIn" : null)
        }
        // loading="lazy"
      />
    );
  }

  // const supportsNativeLoading = useNativeLazyLoading()
  // console.log(supportsNativeLoading)
  return (
    <InView triggerOnce={true}>
      {({ inView, ref }) => (
        <div
          className={props.containerClassName}
          style={{
            height: props.height !== "auto" ? +props.height : props.height,
            width: props.width ? props.width : "auto",
            ...props.style,
          }}
          ref={ref}
        >
          {inView ? renderImage() : null}
          {props.children}
        </div>
      )}
    </InView>
  );
}
