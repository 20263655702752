import { useTranslation } from "next-i18next";
import * as React from "react";
import LazyProductImage from "../../components/lazy-product-image/lazy-product-image";

import styles from "./Footer.module.scss";
import Link from "next/link";

function FooterNewComponent(props: { variant?: "white"; store?: "fr" | "us" }) {
  const { t, i18n } = useTranslation("common");
  const language = i18n.language;
  return (
    <div className={`${styles.footer} ${styles[props.variant] || ""}`}>
      <div className="container align-items-center">
        <div className={`row ${styles.container}`}>
          <div className={`col-4 col-md-5 col-sm-12 ${styles.content}`}>
            <a href="/">
              <LazyProductImage
                height="23"
                width="82"
                alt="Logo-new"
                src="https://public.choose.app/website/new/images/Logo-new.png"
                srcSet={`https://public.choose.app/website/new/images/Logo-new@2x.png 2x,https://public.choose.app/website/new/images/Logo-new@3x.png 3x`}
              />
            </a>
            <p dangerouslySetInnerHTML={{ __html: t("slogan") }} />
            <a
              href={
                props.store === "us"
                  ? "https://www.instagram.com/choose_app_us/"
                  : "https://instagram.com/choose_app"
              }
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className={styles.instagram_icon} />
            </a>
          </div>
          <div className={`col-md-6 col-8 col-sm-12 ${styles.pages}`}>
            <div className="col-md-6 col-sm-12 col-4">
              <h4>{t("footer_about")}</h4>
              <ul>
                <li>
                  <Link
                    href={{
                      pathname: "/about-us",
                      query: { store: props.store ?? "fr", language },
                    }}
                  >
                    {t("footer_history")}
                  </Link>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.welcometothejungle.com/fr/companies/choose"
                  >
                    {t("footer_join")}
                  </a>
                </li>
              </ul>
            </div>
            <div className={`col-md-6 col-sm-12 col-4 ${styles.help}`}>
              <h4>{t("footer_help")}</h4>
              <ul>
                <li>
                  <a href="mailto:contact@appchoose.io">
                    {t("footer_contact")}
                  </a>
                </li>
                {/* <li>
                  <a href="tel:+33780916770">tel: 33 7 80 91 67 70</a>
                </li> */}
                <li>
                  <Link
                    href={{
                      pathname: "/shipping",
                      query: { store: props.store ?? "fr", language },
                    }}
                  >
                    {t("footer_delivery")}
                  </Link>
                </li>
                <li>
                  <Link
                    href={{
                      pathname: "/returns",
                      query: { store: props.store ?? "fr", language },
                    }}
                  >
                    {t("footer_return")}
                  </Link>
                </li>
                {t("footer_payment") !== "footer_payment" ? (
                  <li>
                    <Link
                      href={{
                        pathname: "/payment",
                        query: { store: props.store ?? "fr", language },
                      }}
                    >
                      {t("footer_payment")}
                    </Link>
                  </li>
                ) : null}
              </ul>
            </div>
            <div className={`col-md-6 col-sm-12 col-4 ${styles.pro}`}>
              <h4>{t("footer_pro")}</h4>
              <ul>
                <li>
                  <a
                    href={t("footer_typeform")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("footer_contact_us_pro")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <footer className={`row ${styles.container}`}>
          <div className="col-sm-8 col-5">
            <LazyProductImage
              height="16"
              width="19"
              alt="LOVE"
              src="https://public.choose.app/website/new/images/SmallHeart.png"
              srcSet={`https://public.choose.app/website/new/images/SmallHeart@2x.png 2x,https://public.choose.app/website/new/images/SmallHeart@3x.png 3x`}
            />
            <p style={{ marginLeft: 8 }}>{t("footer_paris")}</p>
          </div>
          <p className={`${styles.copyright} col-sm-4 col-2`}>
            © 2018-2024, Choose
          </p>
          <div className={`col-sm-6 col-5 ${styles.links}`}>
            <Link
              className={styles.copyright}
              href={{
                pathname: "/cgv",
                query: {
                  store: props.store ?? "fr",
                  language,
                },
              }}
            >
              {t("footer_cgv")}
            </Link>
            <Link
              className={styles.copyright}
              href={{
                pathname: "/privacy",
                query: { store: props.store ?? "fr", language },
              }}
            >
              {t("footer_privacy")}
            </Link>
            <Link
              className={styles.copyright}
              href={{
                pathname: "/mentions_legales",
                query: { store: props.store ?? "fr", language },
              }}
            >
              {t("footer_mentions")}
            </Link>
            <Link
              className={styles.copyright}
              href={{
                pathname: "/cgu",
                query: { store: props.store ?? "fr", language },
              }}
            >
              {t("footer_cgu")}
            </Link>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default FooterNewComponent;
